import { INaeStatus } from "../../v3/utils";
export const NaeSStatuses: INaeStatus[] = [
    {
        "type": "status",
        "status": 0,
        "text": "Paid",
        "bgColor": "",
        "brightness": 0,
        "schema": "order",
        "variant": "sky"
    },
    {
        "type": "status",
        "status": 900,
        "text": "Cancel",
        "bgColor": "",
        "brightness": 0,
        "schema": "order",
        "variant": "amber"
    }
]