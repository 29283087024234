import { ORM } from "redux-orm";
import OrderModel from "./OrderModel"
import ProductModel from "./ProductModel"
import QueueModel from "./QueueModel"
import ShippingModel from "./ShippingModel"
import UserModel from "./UserModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    OrderModel,
    ProductModel,
    QueueModel,
    ShippingModel,
    UserModel,
);

export default orm;