
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorOrderNae, SelectorProductNae, SelectorShippingNae, SelectorUserNae } from '../models/ormSelectors';
import { useOrderHookNae } from '../hooks/useOrderHookNae';
import { useProductHookNae } from '../hooks/useProductHookNae';
import { useShippingHookNae } from '../hooks/useShippingHookNae';
import { useUserHookNae } from '../hooks/useUserHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'order') {
    return useOrderHookNae;
  }
  if (schema === 'product') {
    return useProductHookNae;
  }
  if (schema === 'shipping') {
    return useShippingHookNae;
  }
  if (schema === 'user') {
    return useUserHookNae;
  }
  return selector;
}

export function OrderEmptyFieldNae(props: PropsLink) {
  const element = useOrderHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ProductEmptyFieldNae(props: PropsLink) {
  const element = useProductHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ShippingEmptyFieldNae(props: PropsLink) {
  const element = useShippingHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function UserEmptyFieldNae(props: PropsLink) {
  const element = useUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

