import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorOrderNae = createSelector(orm.OrderModel);
export const SelectorProductNae = createSelector(orm.ProductModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorShippingNae = createSelector(orm.ShippingModel);
export const SelectorUserNae = createSelector(orm.UserModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'Order') {
        return SelectorOrderNae;
    }
        if (schema === 'Product') {
        return SelectorProductNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'Shipping') {
        return SelectorShippingNae;
    }
        if (schema === 'User') {
        return SelectorUserNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'order') {
        return SelectorOrderNae;
    }
        if (schema === 'product') {
        return SelectorProductNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'shipping') {
        return SelectorShippingNae;
    }
        if (schema === 'user') {
        return SelectorUserNae;
    }
    }