import { INaeProperty } from "../../v3/utils";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "client",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "currency",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "Currency",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "order",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order",
        "key": "clientEmail",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "clientName",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "clientPhone",
        "type": "string",
        "format": "",
        "title": "Phone",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "order",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "order",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency Rate",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "order",
        "key": "date",
        "type": "string",
        "format": "datetime",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "order",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Doer",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "order",
        "key": "items",
        "type": "array",
        "format": "order-item",
        "title": "Items",
        "additionalProperties": [
            {
                "mapped": "order"
            }
        ],
        "description": "",
        "className": "Order",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "order",
        "key": "itemsSku",
        "type": "array",
        "format": "order-itemsku",
        "title": "SKU",
        "additionalProperties": [
            {
                "mapped": "order"
            }
        ],
        "description": "",
        "className": "Order",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "order",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Payments",
        "additionalProperties": [
            {
                "mapped": "order"
            }
        ],
        "description": "",
        "className": "Order",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "order",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Serial number",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "shippingAddress",
        "type": "string",
        "format": "",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "shippingCity",
        "type": "string",
        "format": "",
        "title": "City",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "shippingCountry",
        "type": "string",
        "format": "",
        "title": "Country",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "shippingPostal",
        "type": "string",
        "format": "",
        "title": "Postal",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "shippingState",
        "type": "string",
        "format": "",
        "title": "State",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "shippings",
        "type": "array",
        "format": "shipping",
        "title": "Get the value of shippings",
        "additionalProperties": [
            {
                "mapped": "order"
            }
        ],
        "description": "",
        "className": "Order",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "order",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "Source",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "order",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "order",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "order",
        "key": "warehouse",
        "type": "rel",
        "format": "warehouse",
        "title": "Warehouse",
        "additionalProperties": [],
        "description": "",
        "className": "Order",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order-item",
        "key": "dateFormatted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "externalId",
        "type": "integer",
        "format": "",
        "title": "Get the value of externalId",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "order-item",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "order-item",
        "key": "order",
        "type": "rel",
        "format": "order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order-item",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "order-item",
        "key": "product",
        "type": "rel",
        "format": "product",
        "title": "Product",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order-item",
        "key": "productBundle",
        "type": "rel",
        "format": "product-bundle",
        "title": "Bundle",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order-item",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "order-item",
        "key": "sku",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "order-itemsku",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItemsku",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "order-itemsku",
        "key": "order",
        "type": "rel",
        "format": "order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItemsku",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order-itemsku",
        "key": "product",
        "type": "rel",
        "format": "product",
        "title": "Product",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItemsku",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order-itemsku",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItemsku",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency Rate",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment",
        "key": "order",
        "type": "rel",
        "format": "order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentCredentialPaypal",
        "type": "rel",
        "format": "payment-credential-paypal",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentCredentialStripe",
        "type": "rel",
        "format": "payment-credential-stripe",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentId",
        "type": "string",
        "format": "",
        "title": "Payment ID",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Serial number",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "Source",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Credit card",
                "value": "cc"
            },
            {
                "label": "Paypal",
                "value": "pp"
            },
            {
                "label": "Wallet",
                "value": "wallet"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "payment",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-credential-paypal",
        "key": "clientId",
        "type": "string",
        "format": "",
        "title": "Client ID",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialPaypal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-paypal",
        "key": "clientSecret",
        "type": "string",
        "format": "",
        "title": "Client secret",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialPaypal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-paypal",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialPaypal",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-credential-paypal",
        "key": "serviceProvider",
        "type": "string",
        "format": "",
        "title": "Service provider",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialPaypal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-paypal",
        "key": "testClientId",
        "type": "string",
        "format": "",
        "title": "Test client ID",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialPaypal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-paypal",
        "key": "testClientSecret",
        "type": "string",
        "format": "",
        "title": "Test client secret",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialPaypal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-paypal",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialPaypal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-stripe",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialStripe",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-stripe",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialStripe",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-credential-stripe",
        "key": "pkKey",
        "type": "string",
        "format": "",
        "title": "PK key",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialStripe",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-stripe",
        "key": "serviceProvider",
        "type": "string",
        "format": "",
        "title": "Service provider",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialStripe",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-stripe",
        "key": "skKey",
        "type": "string",
        "format": "",
        "title": "SK key",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialStripe",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-stripe",
        "key": "testPkKey",
        "type": "string",
        "format": "",
        "title": "Test PK key",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialStripe",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-stripe",
        "key": "testSkKey",
        "type": "string",
        "format": "",
        "title": "Test SK key",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialStripe",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-credential-stripe",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCredentialStripe",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "product",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "product",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "product",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "creator",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "doer",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "product",
        "key": "reportType",
        "type": "string",
        "format": "",
        "title": "Report type",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Main",
                "value": "main"
            },
            {
                "label": "Bump",
                "value": "bump"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "product",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "product",
        "key": "sku",
        "type": "string",
        "format": "",
        "title": "Sku",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "product",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "product",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Physical",
                "value": "physical"
            },
            {
                "label": "Digital",
                "value": "digital"
            },
            {
                "label": "Subscription",
                "value": "subscription"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "product",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Product",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "product-bundle",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundle",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "product-bundle",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundle",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "product-bundle",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "creator",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundle",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-bundle",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "doer",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundle",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-bundle",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundle",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "product-bundle",
        "key": "items",
        "type": "array",
        "format": "product-bundle-item",
        "title": "items",
        "additionalProperties": [
            {
                "mapped": "productBundle"
            }
        ],
        "description": "",
        "className": "ProductBundle",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "product-bundle",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundle",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "product-bundle",
        "key": "sku",
        "type": "string",
        "format": "",
        "title": "SKU",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundle",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "product-bundle",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundle",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "product-bundle",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundle",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "product-bundle-item",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundleItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "product-bundle-item",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "creator",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundleItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-bundle-item",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "doer",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundleItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-bundle-item",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundleItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "product-bundle-item",
        "key": "product",
        "type": "rel",
        "format": "product",
        "title": "Product",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundleItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-bundle-item",
        "key": "productBundle",
        "type": "rel",
        "format": "product-bundle",
        "title": "Product bundle",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundleItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-bundle-item",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundleItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "product-bundle-item",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundleItem",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "product-bundle-item",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductBundleItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "product-cost",
        "key": "cost",
        "type": "number",
        "format": "float",
        "title": "Cost",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "product-cost",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "product-cost",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "creator",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-cost",
        "key": "dateFrom",
        "type": "string",
        "format": "date",
        "title": "Date from",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "product-cost",
        "key": "dateTo",
        "type": "string",
        "format": "date",
        "title": "Date to",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "product-cost",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "doer",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-cost",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "product-cost",
        "key": "product",
        "type": "rel",
        "format": "product",
        "title": "Product",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-cost",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "product-cost",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductCost",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "product-income",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ProductIncome",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "product-income",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency rate",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncome",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "product-income",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncome",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "product-income",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncome",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "product-income",
        "key": "items",
        "type": "array",
        "format": "product-income-item",
        "title": "Items",
        "additionalProperties": [
            {
                "mapped": "productIncome"
            }
        ],
        "description": "",
        "className": "ProductIncome",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "product-income",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Serial number",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncome",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "product-income",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "ProductIncome",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "product-income",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncome",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "product-income",
        "key": "warehouse",
        "type": "rel",
        "format": "warehouse",
        "title": "Warehouse",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ProductIncome",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "product-income-item",
        "key": "cost",
        "type": "number",
        "format": "float",
        "title": "Cost",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncomeItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "product-income-item",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncomeItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "product-income-item",
        "key": "product",
        "type": "rel",
        "format": "product",
        "title": "Product",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncomeItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-income-item",
        "key": "productIncome",
        "type": "rel",
        "format": "product-income",
        "title": "Product income",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncomeItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "product-income-item",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncomeItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "product-income-item",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "ProductIncomeItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "shipping",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Shipping",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "shipping",
        "key": "order",
        "type": "rel",
        "format": "order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "Shipping",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "shipping",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "Shipping",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "shipping",
        "key": "sku",
        "type": "string",
        "format": "",
        "title": "Sku",
        "additionalProperties": [],
        "description": "",
        "className": "Shipping",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "Shipping",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping",
        "key": "stockId",
        "type": "string",
        "format": "",
        "title": "Stock ID",
        "additionalProperties": [],
        "description": "",
        "className": "Shipping",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping",
        "key": "stockSku",
        "type": "string",
        "format": "",
        "title": "Stock SKU",
        "additionalProperties": [],
        "description": "",
        "className": "Shipping",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping",
        "key": "trackingNumber",
        "type": "string",
        "format": "",
        "title": "Tracking number",
        "additionalProperties": [],
        "description": "",
        "className": "Shipping",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping",
        "key": "warehouse",
        "type": "rel",
        "format": "warehouse",
        "title": "Warehouse",
        "additionalProperties": [],
        "description": "",
        "className": "Shipping",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "warehouse",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Warehouse",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "warehouse",
        "key": "countries",
        "type": "string",
        "format": "text",
        "title": "Countries",
        "additionalProperties": [],
        "description": "",
        "className": "Warehouse",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "warehouse",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Warehouse",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "warehouse",
        "key": "priority",
        "type": "integer",
        "format": "",
        "title": "Priority",
        "additionalProperties": [],
        "description": "",
        "className": "Warehouse",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "warehouse",
        "key": "settings",
        "type": "string",
        "format": "text",
        "title": "Settings",
        "additionalProperties": [],
        "description": "",
        "className": "Warehouse",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "warehouse",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "<p>Title<\/p>",
        "className": "Warehouse",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    }
]