import { INaeSchema } from "../../v3/utils"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "Client",
        "schema": "client",
        "title": "Client",
        "titlePlural": "Clients",
        "required": [],
        "scopes": []
    },
    {
        "className": "Currency",
        "schema": "currency",
        "title": "Currency",
        "titlePlural": "Currencies",
        "required": [],
        "scopes": []
    },
    {
        "className": "Order",
        "schema": "order",
        "title": "Order",
        "titlePlural": "Orders",
        "required": [],
        "scopes": [
            "disable-create"
        ]
    },
    {
        "className": "OrderItem",
        "schema": "order-item",
        "title": "Order item",
        "titlePlural": "Order items",
        "required": [],
        "scopes": []
    },
    {
        "className": "OrderItemsku",
        "schema": "order-itemsku",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Payment",
        "schema": "payment",
        "title": "Payment",
        "titlePlural": "Payments",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentCredentialPaypal",
        "schema": "payment-credential-paypal",
        "title": "Paypal credential",
        "titlePlural": "Paypal credentials",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentCredentialStripe",
        "schema": "payment-credential-stripe",
        "title": "Stripe credential",
        "titlePlural": "Stripe credentials",
        "required": [],
        "scopes": []
    },
    {
        "className": "Product",
        "schema": "product",
        "title": "Product",
        "titlePlural": "Products",
        "required": [],
        "scopes": []
    },
    {
        "className": "ProductBundle",
        "schema": "product-bundle",
        "title": "Product bundle",
        "titlePlural": "Product bundles",
        "required": [],
        "scopes": []
    },
    {
        "className": "ProductBundleItem",
        "schema": "product-bundle-item",
        "title": "Product bundle item",
        "titlePlural": "Product bundle items",
        "required": [],
        "scopes": []
    },
    {
        "className": "ProductCost",
        "schema": "product-cost",
        "title": "Product cost",
        "titlePlural": "Product costs",
        "required": [],
        "scopes": []
    },
    {
        "className": "ProductIncome",
        "schema": "product-income",
        "title": "Product income",
        "titlePlural": "Product incomes",
        "required": [],
        "scopes": []
    },
    {
        "className": "ProductIncomeItem",
        "schema": "product-income-item",
        "title": "Product invoice item",
        "titlePlural": "Product invoice items",
        "required": [],
        "scopes": []
    },
    {
        "className": "Shipping",
        "schema": "shipping",
        "title": "Shipping",
        "titlePlural": "Shippings",
        "required": [],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "User",
        "titlePlural": "Users",
        "required": [],
        "scopes": []
    },
    {
        "className": "Warehouse",
        "schema": "warehouse",
        "title": "Warehouse",
        "titlePlural": "Warehouses",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "User": {
        "className": "User",
        "schema": "user"
    },
    "ProductBundle": {
        "className": "ProductBundle",
        "schema": "product-bundle"
    },
    "Product": {
        "className": "Product",
        "schema": "product"
    },
    "ProductCost": {
        "className": "ProductCost",
        "schema": "product-cost"
    },
    "ProductBundleItem": {
        "className": "ProductBundleItem",
        "schema": "product-bundle-item"
    },
    "PaymentCredentialStripe": {
        "className": "PaymentCredentialStripe",
        "schema": "payment-credential-stripe"
    },
    "PaymentCredentialPaypal": {
        "className": "PaymentCredentialPaypal",
        "schema": "payment-credential-paypal"
    },
    "Order": {
        "className": "Order",
        "schema": "order"
    },
    "Client": {
        "className": "Client",
        "schema": "client"
    },
    "Payment": {
        "className": "Payment",
        "schema": "payment"
    },
    "OrderItem": {
        "className": "OrderItem",
        "schema": "order-item"
    },
    "ProductIncomeItem": {
        "className": "ProductIncomeItem",
        "schema": "product-income-item"
    },
    "Warehouse": {
        "className": "Warehouse",
        "schema": "warehouse"
    },
    "ProductIncome": {
        "className": "ProductIncome",
        "schema": "product-income"
    },
    "Currency": {
        "className": "Currency",
        "schema": "currency"
    },
    "OrderItemsku": {
        "className": "OrderItemsku",
        "schema": "order-itemsku"
    },
    "Shipping": {
        "className": "Shipping",
        "schema": "shipping"
    }
}