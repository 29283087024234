import { IOrderFieldsNae } from './useOrderHookNae';
import { IProductFieldsNae } from './useProductHookNae';
import { IShippingFieldsNae } from './useShippingHookNae';
import { IUserFieldsNae } from './useUserHookNae';
import { SelectorOrderNae,SelectorProductNae,SelectorShippingNae,SelectorUserNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.Order.className) {
            return {
                dataToCheck: SelectorOrderNae(store.getState()),
                fields: IOrderFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Product.className) {
            return {
                dataToCheck: SelectorProductNae(store.getState()),
                fields: IProductFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Shipping.className) {
            return {
                dataToCheck: SelectorShippingNae(store.getState()),
                fields: IShippingFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.User.className) {
            return {
                dataToCheck: SelectorUserNae(store.getState()),
                fields: IUserFieldsNae,    
            }
        }
        return undefined;
}