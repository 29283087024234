import { useEffect } from "react";
import { NaeSSchemaMap } from '../../_custom/config/NaeSSchema';
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorOrderNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IOrderModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      status: number,
      serialNumber: string,
      total: number,
  }

export const IOrderFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "status",
    "serialNumber",
    "total"
];

export function useOrderHookNae(id: number) : IOrderModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorOrderNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.Order.schema,
        fields: IOrderFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
